<template>
  <div class="share">
    <van-nav-bar  left-arrow fixed @click-left="goBack" class="nav">
    </van-nav-bar>

    <div class="main-body" >

      <img   :src="(data.product.username ?   data.product.shareWebBuyImage : data.product.shareWebImage )" alt="">

      <p v-if="data.product.username"  class="name">{{data.product.productName}}</p>

      <div v-if="data.product.username" class="bg-img">
        <img :src="data.product.shareImage"  alt="">
      </div>


      <p v-if="data.product.username" class="sc-desc" :class="data.product.productId === 'f43c5f772ea247f897c37396275ac031' ? 'bb-product' : ''">收藏者</p>
      <p v-if="data.product.username" class="sc-name">{{data.product.username}}</p>

      <p  v-if="data.product.username" class="sc-num-desc" :class="data.product.productId === 'f43c5f772ea247f897c37396275ac031' ? 'bb-product' : ''">收藏编号</p>

      <div v-if="data.product.username" class="sc-num">

        <div class="num">{{data.product.number}}</div>
        <div class="leftTop">
          <img :src="require('../../assets/common/icon_share_chain_logo.png')" alt="">
        </div>

      </div>

      <p v-if="data.product.username" class="sc-time-desc" :class="data.product.productId === 'f43c5f772ea247f897c37396275ac031' ? 'bb-product' : ''">生成时间</p>
      <p v-if="data.product.username" class="sc-time">{{ data.product.generatorTime }}</p>

      <div v-if="data.product.username"  class="right-code">

        <div id="qrCode" class="qrcode" ref="qrcodeRed">

        </div>
      </div>

    </div>

  </div>

</template>

<script>
import  Qrcode from 'qrcodejs2'
import { ref, reactive, onMounted, toRefs, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import {clearToast, showFailToast} from "../../utils/util";

export default {
  name: "sharePage",
  setup() {
    const { proxy } = getCurrentInstance();
    const $router = useRouter();
    const data = reactive({
      sWidth:0,
      product:JSON.parse($router.currentRoute.value.query.productDetail)
    })


    console.log(data.product)

    const qrcodeRed = ref(null);
    var qrcode;

    const  makeQrcode = () => {
       qrcode = new Qrcode(document.getElementById('qrCode'),{
        // text 需要转二维码的内容 可以是文本也可以是一个链接 是链接会直接跳走
        text:'https://moment.rednet.cn/',
        width:data.sWidth*73.0/375,
        height:data.sWidth*73.0/375,
        colorDark:'#111',//二维码颜色
        colorLight:'#fff',//二维码背景颜色
        correctLevel:Qrcode.CorrectLevel.L//容错率,L/M/H
      })
    }
    onMounted(()=>{
      data.sWidth = document.body.clientWidth;
      // window.addEventListener('resize', () =>{
      //   data.sWidth = document.body.clientWidth;
      //   qrcode.clear()
      //   makeQrcode();
      // }, false)
      makeQrcode();
    })


    // const loadData = () => {
    //   proxy.$http.get("/wenwu-pubapi-services/dict/detail?dict_key=wenwu_share_product_img").then(res => {
    //     clearToast()
    //     if (res.data.code == 200) {
    //
    //       let listData = res.data.data;
    //       data.imageName = listData.dictValue;
    //
    //
    //     } else {
    //
    //     }
    //   }).catch((err)=>{
    //     clearToast()
    //     showFailToast(err.message)
    //   })
    // }

    // loadData()


    const goBack = (() => {
      $router.back(-1)
    })

    return {
      data,
      goBack,

    };
  }
};
</script>

<style scoped lang="scss" src="./index.scss">

</style>


